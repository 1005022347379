import { CartComponent } from './cart/cart.component';
import { LogoutGuard } from './services/logout.guard';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { HomeLayout } from './layouts/home.layout';
import { LoginLayout } from './layouts/login.layout';
import { AreasComponent } from './areas/areas.component'
import { ListRestaurantsComponent } from './list-restaurants/list-restaurants.component';
import { ProductGroupComponent } from './product-group/product-group.component';
import { ProductComponent } from './product/product.component';
import {AccountHomeComponent} from "./account/account-home/account-home.component";
import {AccountOrdersComponent} from "./account/account-orders/account-orders.component";
import {AccountAddressesComponent} from "./account/account-addresses/account-addresses.component";
import { AccountPasswordComponent } from "./account/account-password/account-password.component";
import { AccountLayoutComponent } from './account/account-layout/account-layout.component';
import {OrderSummaryComponent} from "./order-summary/order-summary.component";
import {OrderProcessingComponent} from "./order-processing/order-processing.component";
import {OrderSuccessComponent} from "./order-success/order-success.component";
import {OrderFailureComponent} from "./order-failure/order-failure.component";
import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AccountOrderDetailsComponent } from './account/account-order-details/account-order-details.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayout,
    children: [
      {
        path: '',
        // component: MainComponent,
        redirectTo: '/areas',
        pathMatch: 'full',
      },
      {
        path: 'areas/:areaId/restaurants',
        component: ListRestaurantsComponent,
        data: {area_name: ''},
      },
      {
        path: 'areas/:areaId/restaurants/:restaurantId',
        component: RestaurantComponent,
        children: [
          {
            path: '',
            component: ProductGroupComponent,
          },
          {
            path: 'product/groups/:productGroupId',
            component: ProductComponent,

          },
        ]
      },

      {
        path: 'areas',
        component: AreasComponent,
      },
      {
        path: 'cart',
        component: CartComponent,
      },
      {
        path: 'account',
        component: AccountLayoutComponent,
        children: [{
            path: '',
            component: AccountHomeComponent,
          },
          {
            path: 'addresses',
            component: AccountAddressesComponent
          },
          {
            path: 'orders',
            component: AccountOrdersComponent
          },
          {
            path: 'password',
            component: AccountPasswordComponent
          },
          {
            path: 'orders/:id',
            component: AccountOrderDetailsComponent
          }
        ],
      },
      {
        path: 'summary',
        component: OrderSummaryComponent,
      },
      {
        path: 'order/success',
        component: OrderSuccessComponent
      },
      {
        path: 'order/processing',
        component: OrderProcessingComponent
      },
      {
        path: 'order/failure',
        component: OrderFailureComponent
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'terms',
        component: TermsComponent,
        // resolve: {
        //   url: 'externalUrlRedirectResolver'
        // },
        data: {
          externalUrl: 'https://docs.speedyjedzonko.pl/Regulamin_speedyjedzonko.pl_31.05.2023.pdf'
        }
      },
      {
        path: 'privacy-policy',
        component: PrivacyComponent,
        // resolve: {
        //   url: 'externalUrlRedirectResolver'
        // },
        data: {
          externalUrl: 'https://docs.speedyjedzonko.pl/Polityka_Prywatnosci_i_cookies.pdf'
        }
      },
    ]

  },
  {
    path: '',
    component: LoginLayout,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'password-recovery',
        component: PasswordRecoveryComponent,
      },
      {
        path: 'password-reset/:token',
        component: PasswordResetComponent,
      },
      {
        path: 'logout',
        canActivate: [LogoutGuard],
        component: HomeLayout,
        pathMatch: 'full',

      },
    ]
  },
  {
    path: '**',
    redirectTo: 'areas'
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
