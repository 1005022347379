import {IngredientGroupItemBuilder} from './ingredientGroupItem.model';

export class IIngredientGroup{
    protected business_group_id: string;
    protected choice_type: string;
    protected description: string;
    protected id: string;
    protected name: string;
    protected display_name: string;
    protected price: number;
    protected restaurant_id: string;
    protected ingredients: Array<any>;
    protected active: boolean;

    get BusinessGroupId(){
        return this.business_group_id;
    }
    get ChoiceType() {
        return this.choice_type;
    }
    get Description() {
        return this.description;
    }
    get Id() {
        return this.id;
    }
    get Name() {
        return this.name;
    }

    get DisplayName() {
      return this.display_name;
    }

    get Price(){
        return this.price;
    }
    get RestaurantId() {
        return this.restaurant_id;
    }
    get Ingredients() {
      return this.ingredients;
    }
    get Active(){
      return this.active;
    }

}
export class IngredientGroup extends IIngredientGroup {

    constructor(builder: IngredientGroupBuilder){
        super();
        this.business_group_id = builder.BusinessGroupId;
        this.choice_type = builder.ChoiceType;
        this.description = builder.Description;
        this.id = builder.Id;
        this.name = builder.Name;
        this.display_name = builder.DisplayName;
        this.price = builder.Price;
        this.restaurant_id = builder.RestaurantId;
        this.ingredients = builder.Ingredients;
        this.active = builder.Active;
    }

    setId(id: string) {
        this.id = id;
        return this;
    }
    setName(name: string) {
        this.name = name;
        return this;
    }

    setDisplayName(name: string) {
        this.display_name = name;
        return this;
    }

    setDescription(description: string) {
        this.description = description;
        return this
    }
    setType(choice_type: string) {
        this.choice_type = choice_type;
        return this;
    }
    setPrice(price: number){
        this.price = price;
        return this;
    }
    setBusinessGroupId(business_group_id: string){
        this.business_group_id = business_group_id;
        return this;
    }
    setRestaurantId(restaurant_id: string){
        this.restaurant_id = restaurant_id;
        return this;
    }
    setIngredients(items: any){
      let ingredientsIDs: Array<any> = Object.keys(items);
      let ingredientsList: Array<any> = Array();
      ingredientsIDs.forEach(id => {

          let item = items[id]
          let ingredientGroupItem = new IngredientGroupItemBuilder(item.name)
            .setPrice(item.price)
            .setId(item.id)
            .setSelected(item.selected)
            .build();

          ingredientsList.push(ingredientGroupItem);
      });
      this.ingredients = ingredientsList;
      return this;
    }

    setActive(active: boolean){
      this.active = active;
      return this;
    }

    toJSON(): Object{
        return{
            id: this.Id,
            name: this.Name,
            display_name: this.DisplayName,
            choice_type: this.ChoiceType,
            description: this.Description,
            price: this.Price,
            ingredients: this.Ingredients,
            active: this.Active
        };
    }
}

export class IngredientGroupBuilder extends IIngredientGroup{
    constructor(name: string){
        super();
        this.name = name;
    }

    setId(id: string) {
        this.id = id;
        return this;
    }
    setName(name: string) {
        this.name = name;
        return this;
    }

    setDisplayName(name: string) {
        this.display_name = name;
        return this;
    }

    setDescription(description: string) {
        this.description = description;
        return this;
    }
    setType(choice_type: string) {
        this.choice_type = choice_type;
        return this;
    }
    setPrice(price: number) {
        this.price = price;
        return this;
    }
    setBusinessGroupId(business_group_id: string){
        this.business_group_id = business_group_id;
        return this;
    }
    setRestaurantId(restaurant_id: string){
        this.restaurant_id = restaurant_id;
        return this;
    }
    setIngredients(ingredients: Array<any>){
      this.ingredients = ingredients;
      return this;
    }
    setActive(active: boolean){
      this.active = active;
      return this;
    }
    build(): IngredientGroup {
        return new IngredientGroup(this);
    }

}
