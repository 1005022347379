<form #productForm="ngForm" [formGroup]="data.form">
  <div mat-dialog-content class="pb-0 p-1 m-1">
    <img rel="preload" as="image" class="img" [src]="data.image" alt="{{ data.name }} image" />
    <h1 mat-dialog-title class="has-text-weight-medium is-size-3 mt-3 mb-3">
      {{ data.form.get("name").value }}
    </h1>
    <p class="product_description">
      {{ data.form.get("description")?.value }}
    </p>
    <div fxLayout="row" fxLayoutAlign="start center" class="mr-4">
      <mat-icon class="mr-1 is-size-5">shopping_basket</mat-icon>
      <span
        ><strong class="has-text-weight-medium is-size-6"
          >{{ data.form.get("price").value | number : "1.2-2" }}zł</strong
        ></span
      >
    </div>

    <input type="hidden" [value]="data.form.id" formControlName="id" />
    <input type="hidden" [value]="data.form.name" formControlName="name" />
    <input type="hidden" [value]="data.form.price" formControlName="price" />

    <ng-container formArrayName="ingredient_groups">
      <ng-container
        *ngFor="
          let ingredientGroup of data.form.get('ingredient_groups').controls;
          let ig = index
        "
      >
        <ng-container [formGroup]="ingredientGroup">
          <input type="hidden" formControlName="name" />
          <input type="hidden" formControlName="id" />
          <input type="hidden" formControlName="price" />

          <h2 class="mt-4 mb-0">
            {{ ingredientGroup.get("display_name").value }}
            <ng-container *ngIf="ingredientGroup.get('price').value !== 0"
              >(+
              {{ ingredientGroup.get("price").value | number : "1.2-2" }}
              zł)</ng-container
            >
          </h2>
          <p class="mb-4 category_description">
            {{ ingredientGroup.get("description").value }}
          </p>
          <ng-container
            formArrayName="ingredients"
            *ngIf="
              ingredientGroup.get('choice_type').value === 'multipleSelection'
            "
          >
            <ng-container
              *ngFor="
                let ingredient of ingredientGroup.get('ingredients').controls;
                let i = index
              "
              [formGroup]="ingredient"
            >
              <fieldset fxLayout="column" class="is-size-5">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="ingredient_group"
                >
                  <label
                    class="checkbox mb-3 is-size-6"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                  >
                    <input type="hidden" formControlName="id" />
                    <input
                      type="checkbox"
                      class="mr-2 modal_checkbox"
                      formControlName="selected"
                    />
                    {{ ingredient.get("name").value }}
                  </label>
                  <!-- <p class="is-size-6" *ngIf="ingredientGroup.price !== 0">+ {{ ingredientGroup.price | number: '1.2-2' }} zł</p> -->
                  <p class="is-size-6">
                    + {{ ingredient.get("price").value | number : "1.2-2" }} zł
                  </p>
                </div>
              </fieldset>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              ingredientGroup.get('choice_type').value === 'singleSelection'
            "
          >
            <div class="select">
              <select formControlName="value">
                <option value="" disabled selected>
                  Wybierz {{ ingredientGroup.get("name").value | lowercase }}
                </option>
                <ng-container
                  *ngFor="
                    let ingredient of ingredientGroup.get('ingredients')
                      .controls;
                    let i = index
                  "
                  [formGroup]="ingredient"
                >
                  <option
                    ngDefaultControl
                    [ngValue]="ingredient.get('id').value"
                    (ngModel)="ingredient.get('id').value"
                    [selected]="ingredient.get('selected').value"
                  >
                    {{ ingredient.get("name").value }}
                    <span
                      >: +{{
                        ingredient.get("price").value | number : "1.2-2"
                      }}
                      zł</span
                    >
                  </option>
                </ng-container>
              </select>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div mat-dialog-actions class="pb-0 p-1 mt-2">
    <div class="product_quantity mr-3">
      <div class="btn" (click)="decreaseQuantity()">&#8722;</div>
      <span class="mr-2 ml-2">{{ data.form.get("quantity").value }}</span>
      <input
        type="hidden"
        formControlName="quantity"
        [value]="data.form.get('quantity').value"
      />
      <div class="btn" (click)="increaseQuantity()">&#43;</div>
    </div>
    <button
      [disabled]="productForm.form.invalid"
      type="submit"
      class="button is-primary submit"
      mat-button
      [matDialogClose]="productForm"
    >
      Dodaj: {{ price | number : "1.2-2" }}zł
    </button>
    <button mat-button matDialogClose="no" class="button is-danger cancel">
      Anuluj
    </button>
    <!-- <button mat-button  cdkFocusInitial [mat-dialog-close]="true" color="warn">Usuń</button> -->
  </div>
</form>
