<main class="panel" fxFlex>
  <p class="panel-heading is-pink">
    {{ title }}
  </p>

  <div class="content" >
    <ng-container *ngIf="order == null">
      <div class="loader"></div>
    </ng-container>

    <ng-container *ngIf="order != null">


        <h3 class="field_name">
          <label class="has-text-weight-medium">Czas zamówienia: </label><span class="data">{{ order.formattedDateString }}</span>
        </h3>

        <h3 class="field_name">
          <label class="has-text-weight-medium">Restauracja: </label><span class="data">{{ order.RestaurantName || 'Brak danych' }}</span>
        </h3>

        <h3 class="field_name">
          <label class="has-text-weight-medium">Status: </label><span class="data status">{{ status.getStatusName(order.Status) }}</span>
        </h3>

        <h3 class="field_name">
          <label class="has-text-weight-medium">Sposób dostawy: </label><span class="data">{{ delivery.getDeliveryName(order.DeliveryType) }} ({{ order.DeliveryFee | number: '1.2-2' }} zł)</span>
        </h3>
        <h3 class="field_name">
          <label class="has-text-weight-medium">Sposób płatności: </label><span class="data">{{ payment.getPaymentName(order.PaymentType) }} (prowizja: {{ order.PaymentFee | number: '1.2-2' }} zł)</span>
        </h3>



        <div class="title_div">
          <h2 class="title">Koszyk</h2>
        </div>

        <section class="content basket">
          <div class="basket_row" *ngFor="let product of order.Cart.CartItems">
            <div>
              <p class="has-text-weight-medium mb-0">
                {{ product.Quantity }}x {{ product.Name }}
              </p>
              <div class="is-size-7">
                <ng-container *ngFor="let ingredientGroup of product.IngredientGroups">
                  <ng-container *ngIf="getSelectedIngredients(ingredientGroup).length > 0">
                    <span>{{ ingredientGroup.Name }}:</span>

                    <ng-container *ngFor="let ingredient of ingredientGroup.Ingredients">
                      <p *ngIf="ingredient.selected == true" class="m-0 has-text-grey ng-star-inserted">
                        {{ ingredient.name }}
                      </p>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <p class="price">
              {{ product.TotalPrice | number:'1.2-2' }} zł
            </p>
          </div>
        </section>

        <h3 class="field_name">
          <label class="has-text-weight-medium">Całkowita kwota do zapłaty: </label><span class="data" style="text-decoration: underline; text-decoration-style: double;">{{ order.Total | number: '1.2-2'}} zł</span>
        </h3>

    </ng-container>
  </div>

</main>
