import { LogoutGuard } from './services/logout.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TileComponent } from './components/tile/tile.component';
import { MaterialModule } from './modules/material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MainComponent } from './main/main.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { HomeLayout } from './layouts/home.layout';
import { LoginLayout } from './layouts/login.layout';
import { AreasComponent } from './areas/areas.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ListRestaurantsComponent } from './list-restaurants/list-restaurants.component';
import { ProductGroupComponent } from './product-group/product-group.component';
import { ProductComponent } from './product/product.component';
import {MatIconModule} from "@angular/material/icon";
import { ProductTileComponent } from './components/product-tile/product-tile.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductModalComponent } from './components/product-modal/product-modal.component';
import { FormsModule }   from '@angular/forms';
import { CartComponent } from './cart/cart.component';
import { AccountNavComponent } from './account/account-nav/account-nav.component';
import { AccountHomeComponent } from './account/account-home/account-home.component';
import { AccountOrdersComponent } from './account/account-orders/account-orders.component';
import { OrdersPanelComponent } from './account/orders-panel/orders-panel.component';
import { AccountAddressesComponent } from './account/account-addresses/account-addresses.component';
import { AccountPasswordComponent } from './account/account-password/account-password.component';
import { TokenInterceptorModule } from './modules/token-interceptor.module';
import { AccountLayoutComponent } from './account/account-layout/account-layout.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrderProcessingComponent } from './order-processing/order-processing.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { OrderFailureComponent } from './order-failure/order-failure.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ResetBasketModalComponent } from './components/reset-basket-modal/reset-basket-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FooterComponent } from './components/footer/footer.component';import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesBannerComponent } from './components/cookies-banner/cookies-banner.component';
import { CheckoutButtonComponent } from './components/checkout-button/checkout-button.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AccountOrderDetailsComponent } from './account/account-order-details/account-order-details.component';


const maskConfig: Partial<IConfig> = {
  validation: true,
};



@NgModule({
  declarations: [
    AppComponent,
    HomeLayout,
    LoginLayout,
    TileComponent,
    MainComponent,
    RestaurantComponent,
    LoginComponent,
    RegisterComponent,
    PasswordRecoveryComponent,
    AreasComponent,
    NavbarComponent,
    ListRestaurantsComponent,
    ProductGroupComponent,
    ProductComponent,
    ProductTileComponent,
    ProductModalComponent,
    CartComponent,
    AccountNavComponent,
    AccountHomeComponent,
    AccountOrdersComponent,
    OrdersPanelComponent,
    AccountAddressesComponent,
    AccountPasswordComponent,
    AccountLayoutComponent,
    OrderSummaryComponent,
    OrderProcessingComponent,
    OrderSuccessComponent,
    OrderFailureComponent,
    ResetBasketModalComponent,
    ErrorModalComponent,
    FooterComponent,
    ContactComponent,
    TermsComponent,
    PrivacyComponent,
    CookiesBannerComponent,
    CheckoutButtonComponent,
    GenericModalComponent,
    PasswordResetComponent,
    AccountOrderDetailsComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatBadgeModule,
    FormsModule,
    TokenInterceptorModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    LogoutGuard,
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      {
          window.location.href = (route.data as any).externalUrl;
      }
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
