import { PaymentType } from './payment.model';
import { IngredientGroup } from "./ingredient_group.model";
import { Product, IProduct, ProductBuilder } from "./product.model";
import { UUID } from 'angular2-uuid';


export class ICartItem {
  protected id: string = '';
  protected quantity: number = 0;
  protected ingredientGroups: IngredientGroup[] = [];
  protected price: number = 0;
  protected name: string = '';
  protected uuid: string = '';

  get Id() {
    return this.id;
  }

  get Quantity() {
    return this.quantity;
  }

  get IngredientGroups() {
    return this.ingredientGroups;
  }

  get Price() {
    return this.price;
  }

  get Name() {
    return this.name;
  }

  get UUID() {
    return this.uuid;
  }

  set UUID(uuid: string) {
    this.uuid = uuid;
  }

  get TotalPrice() {
    let total = this.price;
    this.ingredientGroups.forEach((ingredientGroup: IngredientGroup) => {

      let selected = false;
      ingredientGroup.Ingredients.forEach((ingredient: any) => {
        if (ingredient.selected === true) {

          // one-off fee regardless chosen items
          if (ingredientGroup.Price > 0 && selected === false && ingredientGroup.ChoiceType === "multipleSelection") {
            total += ingredientGroup.Price;
            selected = true;

          } else { // sum up each selected ingredient price
            total += ingredient.price;
          }
        }
      });
    })

    return total * this.quantity * 1.00;
  }

  get AllIngredientNames() {
    let names: string[] = [];
    this.ingredientGroups?.forEach((ingredientGroup: IngredientGroup) => {
      ingredientGroup.Ingredients.forEach((ingredient: any) => {
        if (ingredient.selected === true)
        names.push(ingredient.name);
      })
    });

    return names;
  }
}


export class CartItem extends ICartItem {

  constructor(builder: CartItemBuilder) {
    super();
    this.quantity = builder.Quantity;
    this.ingredientGroups = builder.IngredientGroups;
    this.price = builder.Price;
    this.id = builder.Id;
    this.name = builder.Name;
    this.uuid = builder.UUID;
  }



  setQuantity(quantity: number) {
    this.quantity = quantity;
    return this;
  }


}


export class CartItemBuilder extends ICartItem {

  get Quantity() {
    return this.quantity;
  }

  setId(id: string) {
    this.id = id;
    return this;
  }

  setQuantity(quantity: number) {
    this.quantity = quantity;
    return this;
  }

  setIngredientGroups(groups: IngredientGroup[]) {
    this.ingredientGroups = groups;
    return this;
  }

  setPrice(price: number) {
    this.price = price;
    return this;
  }

  setName(name: string) {
    this.name = name;
    return this;
  }

  setUUID(uuid: string) {
    this.uuid = uuid;
    return this;
  }


  build(): CartItem {
    return new CartItem(this);
  }
}


export class ICart {
  protected cartItems: CartItem[] = [];
  protected deliveryRate: number = 0;
  protected restaurantId: string = '';
  protected restaurantName: string = '';


  get CartItems() {
    return this.cartItems;
  }

  get DeliveryRate() {
    return this.deliveryRate;
  }

  get TotalPrice() {
    let total = 0.00;
    this.cartItems?.forEach((item: CartItem) => {
      total += item.TotalPrice;
    })

    return total;
  }

  get RestaurantId() {
    return this.restaurantId;
  }

  get RestaurantName() {
    return this.restaurantName;
  }


}

export class Cart extends ICart {

  constructor(builder: CartBuilder) {
    super();
    this.cartItems = builder.CartItems;
    this.deliveryRate = builder.DeliveryRate;
    this.restaurantId = builder.RestaurantId;
    this.restaurantName = builder.RestaurantName;
  }


  addItem(item: CartItem) {
    item.UUID = UUID.UUID();
    this.cartItems.push(item);
  }

  removeItem(item: CartItem) {
    let newcartItems = this.cartItems.filter((o: CartItem) => o.UUID !== item.UUID) ;
    this.cartItems = newcartItems;
  }

  setRestaurantId(restaurantId: string) {
    this.restaurantId = restaurantId;
    return this;
  }

  setRestaurantName(restaurantName: string) {
    this.restaurantName = restaurantName;
    return this;
  }

  setDeliveryRate(deliveryRate: number) {
    this.deliveryRate = deliveryRate;
    return this;
  }

  toJSON() {
    return {
      delivery_rate: this.DeliveryRate,
      restaurant_id: this.RestaurantId,
      restaurant_name: this.RestaurantName,
      cart_items: this.CartItems.map((item: CartItem) => {
        return {
          quantity: item.Quantity,
          ingredient_groups: item?.IngredientGroups?.map((ingredientGroup: IngredientGroup) => {
            return {
              choice_type: ingredientGroup.ChoiceType,
              description: ingredientGroup.Description,
              id: ingredientGroup.Id,
              name: ingredientGroup.Name,
              price: ingredientGroup.Price,
              restaurant_id: ingredientGroup.RestaurantId,
              ingredients: ingredientGroup.Ingredients.map((ingredient: any) => {
                return {
                  selected: ingredient.selected,
                  price: ingredient.price,
                  name: ingredient.name,
                  id: ingredient.id,
                }
              }),
            }
          }),
          price: item.Price,
          id: item.Id,
          name: item.Name,
          uuid: item.UUID,
        }
      }),
    }
  }
}


export class CartBuilder extends ICart{
  constructor(){
      super();
  }

  setItems(items: CartItem[]) {
    this.cartItems = items;
    return this;
  }

  setRestaurantId(restaurantId: string) {
    this.restaurantId = restaurantId;
    return this;
  }

  setRestaurantName(restaurantName: string) {
    this.restaurantName = restaurantName;
    return this;
  }

  setDeliveryRate(deliveryRate: number) {
    this.deliveryRate = deliveryRate;
    return this;
  }

  build(): Cart {
    return new Cart(this);
  }

  load(json: string|null): Cart {
    let _default = `
    {
      "delivery_rate": 0.00,
      "restaurant_id": "",
      "restaurant_name": "",
      "cart_items": []
    }`;

    let jCart: any = '';

    try {
      jCart = JSON.parse(json || _default);
    } catch (e) {
      console.log(e);
      jCart = JSON.parse(_default);
    }

    return new CartBuilder()
      .setDeliveryRate(jCart.delivery_rate)
      .setRestaurantId(jCart.restaurant_id)
      .setRestaurantName(jCart.restaurant_name)
      .setItems(jCart.cart_items?.map((item: any) => {
        return new CartItemBuilder()
          .setQuantity(item.quantity)
          .setPrice(item.price)
          .setId(item.id)
          .setName(item.name)
          .setUUID(item.uuid)
          .setIngredientGroups(item.ingredient_groups?.map((group: any) => {
            return <IngredientGroup>{
              ChoiceType: group.choice_type,
              Description: group.description,
              Id: group.id,
              Name: group.name,
              Price: group.price,
              Ingredients: group.ingredients?.map((ingredient: any) => {
                return {
                  selected: ingredient.selected,
                  price: ingredient.price,
                  name: ingredient.name,
                  id: ingredient.id,
                }
              }),
            }
          }))
          .build()

      }))
      .build();
  }


}
