export class DeliveryType {
  public static HOME_DELIVERY = 1;
  public static COLLECTION_DELIVERY = 2;


  public static getDeliveryName(val: number){
    let name: string = 'Nieznany';
    switch (val) {
      case this.HOME_DELIVERY: name = 'Dostawa do domu'; break
      case this.COLLECTION_DELIVERY: name = 'Odbiór osobisty'; break
    }
    return name;

  }
}
