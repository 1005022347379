export class PaymentType {
  public static STRIPE_ONLINE_GLOBAL_PAYMENT = 1;
  public static CASH_PAYMENT = 2;
  public static HOTPAY_ONLINE_GLOBAL_PAYMENT = 3;

  public static getPaymentName(val: number){
    let name: string = 'Nieznany';
    switch (val) {
      case this.STRIPE_ONLINE_GLOBAL_PAYMENT: name = 'Online (Stripe)'; break
      case this.CASH_PAYMENT: name = 'Gotówka'; break
      case this.HOTPAY_ONLINE_GLOBAL_PAYMENT: name = 'Online (Hotpay)'; break;
    }
    return name;

  }
}
