import { Address } from 'src/app/models/address.model';
import { CartBuilder, CartItemBuilder } from './../models/cart.model';
import { Restaurant, RestaurantBuilder } from './../models/restaurant.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config';
import { catchError, filter, map, pluck, retry } from 'rxjs/operators';
import { Area, AreaBuilder } from '../models/area.model';
import { Observable, of, throwError } from 'rxjs';
import { ProductGroup, ProductGroupBuilder } from '../models/product_group.model';
import { Product, ProductBuilder } from '../models/product.model';
import { IngredientGroup, IngredientGroupBuilder } from '../models/ingredient_group.model'
import { Account, AccountBuilder } from '../models/account.model';
import { Order, OrderBuilder } from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getAreas(): Observable<Area[]> {
    return this.http.get(`${Config.Host}/areas/`).pipe(
      map((response: any) => {

          if (response.status === 1)
            return response.areas.map((o: any) =>
              new AreaBuilder(o.name).setId(o.id).setPhoto(o.photo).build())

          return null;

        }
      ),
      retry(3),
    );
  }

  getRestaurantsByArea(area: Area): Observable<Restaurant[]> {
    return this.http.get(`${Config.Host}/areas/${area.Id}/restaurants/`).pipe(
      map((response: any) => {
        if (response.status === 1)
          return response.restaurants.map((o: any) =>
            new RestaurantBuilder(o.name)
              .setId(o.id)
              .setLogo(o.logo)
              .setEmail(o.email)
              .setAreaId(o.address.area_id)
              .setAreaName(o.address.area_name)
              .setAddress1(o.address.address1)
              .setAddress2(o.address.address2)
              .setAddress3(o.address.address3)
              .setCity(o.address.city)
              .setPostcode(o.address.postcode)
              .setPhone(o.address.phone)
              .setDeliveryTime(o.delivery_time)
              .setMinOrderValue(o.min_order_value)
              .setDeliveryRate(o.delivery_rate)
              .setActive(o.active)
              .setDisabledOrders(o.disabled_orders)
              .build()

          )
          .filter((o: Restaurant) => o.Active === true)
      })
    );
  }

  recoverPassword(email: string) {
    return this.http.post(`${Config.Host}/customers/account/password-recovery`, {
      "email": email,
    });
  }

  resetPassword(password: string, token: string) {
    return this.http.post(`${Config.Host}/customers/account/password-reset`, {
      "password": password,
    },
    {
      headers: {
        "Authorization": `Bearer ${token}`,
      }

    });
  }

  login(email: string, password: string) {
    return this.http.post(`${Config.Host}/customers/login`, {
      "email": email,
      "password": password,
    });
  }

  getRestaurant(restaurant: Restaurant): Observable<Restaurant>|any {
    return this.http.get(`${Config.Host}/areas/${restaurant.AreaId}/restaurants/${restaurant.Id}/`).pipe(
      pluck('restaurant'),
      map((o: any) => {
          return   new RestaurantBuilder(o.name)
                .setId(o.id)
                .setLogo(o.logo)
                .setEmail(o.email)
                .setAreaId(o.address.area_id)
                .setAreaName(o.address.area_name)
                .setAddress1(o.address.address1)
                .setAddress2(o.address.address2)
                .setAddress3(o.address.address3)
                .setCity(o.address.city)
                .setPostcode(o.address.postcode)
                .setPhone(o.address.phone)
                .setDeliveryTime(o.delivery_time)
                .setMinOrderValue(o.min_order_value)
                .setDeliveryRate(o.delivery_rate)
                .setCategories(o.categories)
                .setOpeningHoursDescription(o.opening_hours_description)
                .setDisabledOrders(o.disabled_orders)
                .build()

      }),
      retry(3),
      catchError(val => throwError(`error: ${val}`)),
    );
  }

  getProductGroups(restaurant: Restaurant): Observable<ProductGroup[]> {
    return this.http.get(`${Config.Host}/areas/${restaurant.AreaId}/restaurants/${restaurant.Id}/product/groups/`).pipe(
      map((response: any) => {
        if (response?.status === 1)
          return response.product_groups.map((o: any) =>
            new ProductGroupBuilder(o.name)
              .setId(o.id)
              .setActive(o.active)
              .setBusinessGroupId(o.business_group_id)
              .setRestaurantId(o.restaurant_id)
              .setDescription(o.description)
              .setPosition(o.position)
              .build()
          )
      }),
      retry(3)
    );

  }

  getProducts(productGroup: ProductGroup): Observable<Product[]> {
    return this.http.get(`${Config.Host}/areas/${productGroup.AreaId}/restaurants/${productGroup.RestaurantId}/product/groups/${productGroup.Id}/products/`).pipe(
      map((response: any) => {
        if (response?.status === 1)
          return response.products.map((o: any) =>
            new ProductBuilder(o.name)
              .setId(o.id)
              .setActive(o.active)
              .setBusinessGroupId(o.business_group_id)
              .setRestaurantId(o.restaurant_id)
              .setIngredientGroups(o.ingredient_groups)
              .setPrice(o.price)
              .setDescription(o.description)
              .setPhoto(o.photo)
              .setProductGroupId(o.product_group_id)
              .build()
            )
            .filter((o: Product) => o.Active === true)

      }),
      retry(3),
      catchError(val => throwError(`error: ${val}`)),
    )
  }

  getIngredientGroup(ingredientGroup: IngredientGroup): Observable<IngredientGroup>{
    return this.http.get(`${Config.Host}/${ingredientGroup.BusinessGroupId}/restaurants/${ingredientGroup.RestaurantId}/ingredient/groups/${ingredientGroup.Id}/`).pipe(
        pluck('ingredient_group'),
        map((ingredientGroup: any) => {
          return new IngredientGroupBuilder(ingredientGroup.name)
            .setId(ingredientGroup.id)
            .setName(ingredientGroup.name)
            .setDisplayName(ingredientGroup.display_name)
            .setType(ingredientGroup.choice_type)
            .setDescription(ingredientGroup.description)
            .setPrice(ingredientGroup.price)
            .setBusinessGroupId(ingredientGroup.business_group_id)
            .setRestaurantId(ingredientGroup.restaurant_id)
            .build()
      }),
      retry(2),
    );
   }

   getIngredientsGroups(restaurant: Restaurant): Observable<IngredientGroup[]>{
    return this.http.get(`${Config.Host}/areas/${restaurant.AreaId}/restaurants/${restaurant.Id}/ingredient/groups/`).pipe(
      pluck('ingredient_groups'),
      map((data: any) => (data || Array()).map((ingredientGroup: any) => {
        return new IngredientGroupBuilder(ingredientGroup.name)
          .setId(ingredientGroup.id)
          .setName(ingredientGroup.name)
          .setDisplayName(ingredientGroup.display_name)
          .setType(ingredientGroup.choice_type)
          .setDescription(ingredientGroup.description)
          .setPrice(ingredientGroup.price)
          .setIngredients(ingredientGroup.ingredients)
          .build()
      }),
      retry(2),
    ));
  }

  changePassword(password: string) {
    return this.http.post(`${Config.Host}/customers/account/password`, {"password": password});
  }

  changeAddress(address: Address) {
    let payload = {
      "area_id": "00000000-0000-0000-0000-000000000000",
      "address1": address.Address1,
      "address2": address.Address2,
      "address3": address.Address3,
      "postcode": address.Postcode,
      "city": address.City,
      "phone": address.Phone
    }

    return this.http.post(`${Config.Host}/customers/account/address`, payload).pipe(
      retry(2)
    )
  }

  getAccount(): Observable<Account> {
    return this.http.get(`${Config.Host}/customers/account/`).pipe(
      map((response: any) => {
          let account = response.customer;
          return new AccountBuilder()
              .setName(account.name)
              .setLastName(account.last_name)
              .setEmail(account.email)
              .setAddress1(account.address.address1)
              .setAddress2(account.address.address2)
              .setAddress3(account.address.address3)
              .setCity(account.address.city)
              .setPostcode(account.address.postcode)
              .setPhone(account.address.phone)
              .build();
      }),
      retry(3),
      catchError(val => throwError(`error: ${val}`)),
    )
  }

  getOrders(): Observable<Order[]> {
    return this.http.get(`${Config.Host}/customers/account/orders/`).pipe(
      map((response: any) => {
        if (response?.status === 1)
          return response.orders.map((o: any) => {
            console.log(o.id);

            return new OrderBuilder()
            .setId(o.id)
            .setBusinessGroupId(o.business_group_id)
            .setRestaurantId(o.restaurant_id)
            .setPayment(o.payment_type)
            .setPaymentFee(o.payment_fee)
            .setDelivery(o.delivery_type)
            .setDeliveryFee(o.delivery_fee)
            .setStatus(o.status)
            .setCreated(o.created)
            .setUpdated(o.updated)
            .setCart(
              new CartBuilder()
                .setDeliveryRate(o.cart.delivery_rate)
                .setRestaurantId(o.restaurant_id)
                .setItems(o.cart.cart_items?.map((item: any) => {
                  return new CartItemBuilder()
                    .setQuantity(item.quantity)
                    .setPrice(item.price)
                    .setId(item.id)
                    .setName(item.name)
                    .setIngredientGroups(item.ingredient_groups?.map((group: any) => {
                      return <IngredientGroup>{
                        ChoiceType: group.choice_type,
                        Description: group.description,
                        Id: group.id,
                        Name: group.name,
                        DisplayName: group.display_name,
                        Price: group.price,
                        Ingredients: group.ingredients?.map((ingredient: any) => {
                          return {
                            selected: ingredient.selected,
                            price: ingredient.price,
                            name: ingredient.name,
                            id: ingredient.id,
                          }
                        }),
                      }
                    }))
                    .build() // items

                }))
              .build() // cart
            )
            .build() // order
          })
      }),
      retry(3),
    )
  }

  getOrderById(id: string): Observable<Order> {
    return this.http.get(`${Config.Host}/customers/account/orders/${id}/`).pipe(
      map((response: any) => {
        if (response.status !=1) {
          return new OrderBuilder().build();
        }


        let o: any = response.order;
        return new OrderBuilder()
        .setId(o.id)
        .setBusinessGroupId(o.business_group_id)
        .setRestaurantId(o.restaurant_id)
        .setRestaurantName(o.restaurant_name)
        .setPayment(o.payment_type)
        .setPaymentFee(o.payment_fee)
        .setDelivery(o.delivery_type)
        .setDeliveryFee(o.delivery_fee)
        .setStatus(o.status)
        .setCreated(o.created)
        .setUpdated(o.updated)
        .setCart(
          new CartBuilder()
            .setDeliveryRate(o.cart.delivery_rate)
            .setRestaurantId(o.restaurant_id)
            .setItems(o.cart.cart_items?.map((item: any) => {
              return new CartItemBuilder()
                .setQuantity(item.quantity)
                .setPrice(item.price)
                .setId(item.id)
                .setName(item.name)
                .setIngredientGroups(item.ingredient_groups?.map((group: any) => {
                  return <IngredientGroup>{
                    ChoiceType: group.choice_type,
                    Description: group.description,
                    Id: group.id,
                    Name: group.name,
                    DisplayName: group.display_name,
                    Price: group.price,
                    Ingredients: group.ingredients?.map((ingredient: any) => {
                      return {
                        selected: ingredient.selected,
                        price: ingredient.price,
                        name: ingredient.name,
                        id: ingredient.id,
                      }
                    }),
                  }
                }))
                .build() // items

            }))
          .build() // cart
        )
        .build() // order

      })
    )
  }

  createOrder(order: any) {
    return this.http.put(`${Config.Host}/restaurants/${order.cart.restaurant_id}/orders`, order);
  }

  register(fields: any): any {
    let account = {
      "name": fields.name,
      "last_name": fields.last_name,
      "password": fields.password,
      "email": fields.email,
      "address": {
        "address1": fields.address1,
        "address2": fields.address2,
        "address3": fields.address3,
        "postcode": fields.postcode,
        "city": fields.city,
        "phone": fields.phone,
      }
    }
    return this.http.post(`${Config.Host}/customers/register`, account);
  }
}
