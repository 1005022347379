import { DataService } from 'src/app/services/data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order, OrderBuilder } from 'src/app/models/order.model';
import { CartBuilder } from 'src/app/models/cart.model';
import { OrderStatus } from 'src/app/models/order_status.model';
import { PaymentType } from 'src/app/models/payment.model';
import { DeliveryType } from 'src/app/models/delivery_type.model';
import { IngredientGroup } from 'src/app/models/ingredient_group.model';

@Component({
  selector: 'app-account-order-details',
  templateUrl: './account-order-details.component.html',
  styleUrls: ['./account-order-details.component.css']
})
export class AccountOrderDetailsComponent implements OnInit {

  title = "Szczegóły zamówienia";
  orderId = "";
  order: Order|null = null;
  loading = false;

  status = OrderStatus;
  payment = PaymentType;
  delivery = DeliveryType;

  constructor(
    public data: DataService,
    public route: ActivatedRoute,
  ) {
    this.route.params.subscribe((data: any) => {
      this.orderId = data.id;
      this.title = `${this.title} ${this.orderId}`;
    })
  }

  getSelectedIngredients(ingredientGroup: IngredientGroup) {
    let ingredients = ingredientGroup.Ingredients.filter((ingredient) => { return (ingredient.selected == true)});
    console.log(ingredients);
    return ingredients;
  }

  ngOnInit(): void {
    this.getOrderById(this.orderId).subscribe({
      next: (order: Order) => {
        this.order = order;
        this.status.getStatusName(order.Status);
      },
      error: (ee: Error) => {

      },
      complete: () => {
        this.loading = false;
      }
    });

  }


    getOrderById(id: string) {
      return this.data.getOrderById(id);
    }
}
