import {Component, Input, OnInit} from '@angular/core';
import { Order } from 'src/app/models/order.model';
import { DataService } from 'src/app/services/data.service';
import { OrderStatus } from "../../models/order_status.model";

@Component({
  selector: 'app-orders-panel',
  templateUrl: './orders-panel.component.html',
  styleUrls: ['./orders-panel.component.css']
})
export class OrdersPanelComponent implements OnInit {

  orders: Order[]|null = null;
  loading = false;

  constructor(
    private api: DataService,
  ) {


  }

  @Input() limit?: number;
  @Input() title: string = "";
  Status = OrderStatus;



  ngOnInit(): void {
    this.loading = true;
    this.getOrders(this.limit);
  }

  getOrders(limit: number|undefined) {
    console.log("get orders");
    this.api.getOrders().subscribe({
      next: (orders: Order[]) => {
        this.orders = orders;
        this.orders.sort((a,b) => (a.Created < b.Created) ? 1 : ((b.Created < a.Created) ? -1 : 0))


      },
      error: (err: Error) => {
        console.log(err);
        // TODO: display error
      },
      complete: () => {
        // TODO: hide loader
        this.loading = false;
      }

    })
  }

}
